<template>
  <vx-card class="mb-8">
    <vs-row vs-spacer="2">
      <vs-col vs-w="2" vs-offset=".1">
        <country-select
          v-on:value="filters.countries = $event"
          :disabled="loadingLicenses"
        />
      </vs-col>
    </vs-row>
  </vx-card>
</template>
<script>
import CountrySelect from '../country-select/CountrySelect.vue'

export default {
  components: {
    CountrySelect
  },
  props: {
    loadingLicenses: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      filters: {
        countries: [{ value: 'all', text: 'Todos' }]
      }
    }
  },
  watch: {},
  computed: {},
  methods: {},
  beforeMount() {
    this.$emit('value', this.filters)
  }
}
</script>
