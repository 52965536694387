var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    [
      _c("licenses-filter", {
        attrs: { loadingLicenses: _vm.loadingLicenses },
        on: {
          value: function ($event) {
            _vm.filters = $event
          },
        },
      }),
      _c("licenses-table", {
        attrs: { licenses: _vm.licenses, loadingLicenses: _vm.loadingLicenses },
        on: { updateData: _vm.updateLicenses },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }