var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { staticClass: "mb-8" },
    [
      _c(
        "vs-row",
        { attrs: { "vs-spacer": "2" } },
        [
          _c(
            "vs-col",
            { attrs: { "vs-w": "2", "vs-offset": ".1" } },
            [
              _c("country-select", {
                attrs: { disabled: _vm.loadingLicenses },
                on: {
                  value: function ($event) {
                    _vm.filters.countries = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }