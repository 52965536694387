<template>
  <vx-card>
    <licenses-filter
      v-on:value="filters = $event"
      :loadingLicenses="loadingLicenses"
    ></licenses-filter>
    <licenses-table
      :licenses="licenses"
      :loadingLicenses="loadingLicenses"
      @updateData="updateLicenses"
    ></licenses-table>
  </vx-card>
</template>
<script>
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import appConfig from '@/../appConfig.js'
import LicensesTable from '../components/licenses-table/LicensesTable.vue'
import LicensesFilter from '../components/licenses-filter/LicensesFilter.vue'
import qs from 'qs'

export default {
  components: {
    LicensesTable,
    LicensesFilter
  },
  data() {
    return {
      licenses: [],
      loadingLicenses: false,
      filters: null
    }
  },
  watch: {
    filters: {
      handler(_, oldValue) {
        if (!oldValue) return
        this.updateLicenses()
      },
      deep: true
    }
  },
  computed: {},
  methods: {
    getLicensesData() {
      this.countSelected = 0
      this.loadingLicenses = true
      this.$http
        .get(`${appConfig.apiUrl}/licenses`, {
          params: this.parseFilter(),
          paramsSerializer: (params) => {
            return qs.stringify(params, {
              arrayFormat: 'brackets'
            })
          }
        })
        .then((response) => {
          this.licenses = response.data
        })
        .catch((error) => {
          console.log(error)
        })
        .then(() => {
          this.loadingLicenses = false
        })
    },
    parseFilter() {
      return {
        countries: this.setAtributeFilter('countries')
      }
    },
    setAtributeFilter(name) {
      if (this.filters[name].some((elment) => elment.value === 'all')) return []
      return this.filters[name].map((element) => element.alpha_2_code)
    },
    updateLicenses() {
      this.getLicensesData()
    }
  },
  mounted() {
    this.getLicensesData()
  }
}
</script>
