<template>
  <div>
    <vx-card>
      <div class="flex flex-wrap justify-between items-center font-12">
        <!-- ITEMS PER PAGE -->
        <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium height-32">
              <span class="mr-2"
                >{{
                  chargesCurrentPage * chargesPaginationPageSize -
                  (chargesPaginationPageSize - 1)
                }}
                -
                {{
                  licenses.length -
                    chargesCurrentPage * chargesPaginationPageSize >
                  0
                    ? chargesCurrentPage * chargesPaginationPageSize
                    : licenses.length
                }}
                of {{ licenses.length }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>

              <vs-dropdown-item @click="chargesGridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="chargesGridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="chargesGridApi.paginationSetPageSize(100)">
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="chargesGridApi.paginationSetPageSize(150)">
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <div
          class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
        >
        <vs-button
            class="mb-4 md:mb-0 mr-4"
            v-if="this.countSelected"
            @click="openPopupChangeState('charged')"
            size="small"
          >
            Cambiar estado cobrado ({{ this.countSelected }})
          </vs-button>
          <vs-input
            class="mb-4 md:mb-0 mr-4"
            v-model="chargesSearchQuery"
            @input="updateChargesSearchQuery"
            placeholder="Buscar..."
          />
          <vs-button
            class="mb-4 md:mb-0 mr-4"
            @click="chargesGridApi.exportDataAsExcel({rowHeight: 20})"
            >Exportar Excel</vs-button
          >
          <vx-tooltip text="Refrescar datos">
            <vs-button
              class="mb-4 md:mb-0 mr-4"
              radius
              color="primary"
              type="border"
              icon-pack="feather"
              icon="icon-refresh-cw"
              @click="updateData"
            ></vs-button>
          </vx-tooltip>
        </div>
      </div>
    <ag-grid-vue
        ref="agGridTable"
        :gridOptions="chargesGridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columns"
        :defaultColDef="chargesDefaultColDef"
        :rowData="licenses"
        :loadingData="loadingLicenses"
        rowSelection="multiple"
        rowMultiSelectWithClick="true"
        :enableRangeSelection="true"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="chargesPaginationPageSize"
        :getContextMenuItems="getChargesContextMenuItems"
        :suppressPaginationPanel="true"
        tooltipShowDelay="0"
        @selection-changed="onSelectionChanged"
        :frameworkComponents="frameworkComponents"
      >
    </ag-grid-vue>
    <vs-pagination
        :total="chargesTotalPages"
        :max="chargesMaxPageNumbers"
        v-model="chargesCurrentPage"
      />
    </vx-card>
    <vs-popup :title="popupActiveChangeStateTitle" :active.sync="popupActiveChangeState">
      <vs-table noDataText="Error" :data="selectedRows">
        <template slot="thead">
          <vs-th>ID</vs-th>
          <vs-th>Nombre</vs-th>
          <vs-th>Estado actual</vs-th>
          <vs-th>Nuevo estado</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].id">
              {{ data[indextr].id }}
            </vs-td>
            <vs-td :data="data[indextr].company_name">
              {{ data[indextr].company_name }}
            </vs-td>
            <vs-td :data="data[indextr].start_date" v-if="popupActiveChangeStateType == 'charged'">
              <vs-chip color="success" v-if="data[indextr].license.charged">COBRADO</vs-chip>
              <vs-chip color="danger" v-else>NO COBRADO</vs-chip>
            </vs-td>
            <vs-td :data="data[indextr].start_date" v-if="popupActiveChangeStateType == 'charged'">
              <vs-chip color="success" v-if="!data[indextr].license.charged">COBRADO</vs-chip>
              <vs-chip color="danger" v-else>NO COBRADO</vs-chip>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-button @click="changeState()" color="primary" type="filled" class="float-right mt-2">Cambiar</vs-button>
    </vs-popup>
  </div>
</template>
<script>
import AgTable from '../ag-table/AgTable.vue'
import { AgGridVue } from 'ag-grid-vue'
import appConfig from '@/../appConfig.js'
import LicensesChargedRenderer from '../../views/LicensesChargedRenderer.vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import _spanishLocaleText from '@/constants/spanishLocaleText.js'
const user = JSON.parse(localStorage.getItem('userInfo')) || {}

export default {
  components: {
    AgTable,
    AgGridVue,
    LicensesChargedRenderer
  },
  props: {
    licenses: {
      type: [],
      required: true
    },
    loadingLicenses: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      chargesSearchQuery: '',
      chargesGridApi: null,
      chargesMaxPageNumbers: 7,
      chargesGridOptions: {
        localeText: _spanishLocaleText.getText()
      },
      chargesDefaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
        headerHeight: 10
      },
      countSelected: 0,
      selectedRows: [],
      popupActiveChangeState: false,
      popupActiveChangeStateTitle: 'Cambiar estado',
      popupActiveChangeStateType: '',
      columns: [
        {
          headerName: 'Venta en el DSP',
          headerClass: 'column-group-1',
          children: [
            {
              headerCheckboxSelection: true,
              checkboxSelection: true,
              width: 50
            },
            {
              headerName: 'Holding',
              field: 'holding_name',
              filter: true,
              width: 300
            },
            {
              headerName: 'Empresa',
              field: 'company_name',
              filter: true,
              width: 300
            },
            {
              headerName: 'Cobrado',
              field: 'license.charged',
              width: 150,
              cellRendererFramework: LicensesChargedRenderer,
              filter: true,
              refData: this.licensesChargesMapping()
            },
            {
              headerName: 'Plan',
              field: 'active_plan',
              filter: true,
              width: 150,
              cellEditor: 'agSelectCellEditor',
              cellClass: 'editable-cell',
              cellEditorParams: {
                values: ['free', 'pro', 'enterprise']
              },
              editable: true,
              onCellValueChanged: (params) =>
                this.putLicensesData(params, 'active_plan')
            },
            {
              headerName: 'Divisa',
              field: 'currency',
              filter: true,
              width: 120
            },
            {
              headerName: 'Precio Total',
              valueGetter: (params) => parseFloat(params.data.license.price).toFixed(2),
              valueFormatter: this.currencyFormatter,
              field: 'license.price',
              filter: true,
              width: 250,
              cellClass: 'editable-cell',
              editable: () =>
                user.permissions.includes('list_panel_statistic_campaigns'),
              onCellValueChanged: (params) =>
                this.putLicensesData(params, 'price')
            },
            {
              headerName: 'Descuento',
              field: 'license.discount',
              filter: true,
              width: 250,
              cellClass: 'editable-cell',
              editable: () =>
                user.permissions.includes('list_panel_statistic_campaigns'),
              onCellValueChanged: (params) =>
                this.validDiscount(params) && this.putLicensesData(params, 'discount')
            },
            {
              headerName: 'Precio con Descuento',
              valueGetter: (params) => parseFloat(this.calculateDiscount(params)).toFixed(2),
              valueFormatter: this.currencyFormatter,
              filter: true,
              width: 250
            },
            {
              headerName: 'Pantallas Total',
              field: 'displays_count',
              filter: true,
              width: 180
            },
            {
              headerName: 'Precio por Pantalla',
              valueGetter: (params) => this.calculatePricePerScreen(params),
              valueFormatter: this.currencyFormatter,
              filter: true,
              width: 250
            },
            {
              headerName: 'Usan cámara',
              field: 'camara_displays_count',
              filter: true,
              width: 180
            },
            {
              headerName: 'Pantallas De Prueba',
              field: 'test_displays_count',
              filter: true,
              width: 180
            },
            {
              headerName: 'Fecha de Alta',
              valueGetter: (params) =>
                this.$options.filters.dateFormatter(params.data.created_at),
              filter: true,
              width: 150
            },
            {
              headerName: 'Fecha Modificacion',
              valueGetter: (params) =>
                this.$options.filters.dateFormatter(
                  params.data.license.updated_at
                ),
              filter: true,
              width: 200
            },
            {
              headerName: 'Observaciones',
              field: 'license.observations',
              filter: true,
              width: 300,
              editable: true,
              valueGetter: (params) => params.data.license.observations,
              onCellValueChanged: (params) => this.putLicensesData(params, 'observations')
            }
          ]
        }
      ]
    }
  },
  watch: {},
  computed: {
    // Charges table computed
    chargesPaginationPageSize () {
      if (this.chargesGridApi) return this.chargesGridApi.paginationGetPageSize()
      else return 100
    },
    chargesTotalPages () {
      if (this.chargesGridApi) return this.chargesGridApi.paginationGetTotalPages()
      else return 0
    },
    chargesCurrentPage: {
      get () {
        if (this.chargesGridApi) return this.chargesGridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.chargesGridApi.paginationGoToPage(val - 1)
      }
    }
  },
  methods: {
    calculatePricePerScreen(params) {
      const price = parseFloat(params.data.license.price)
      const discount = parseFloat(params.data.license.discount)
      const displayCount = parseFloat(params.data.displays_count)

      if (!isNaN(price) && !isNaN(displayCount) && displayCount !== 0) {
        if (discount === 0) {
          return (price / displayCount).toFixed(2)
        } else {
          const discountedPrice = price - price * (discount / 100)
          return (discountedPrice / displayCount).toFixed(2)
        }
      }
      return 0
    },
    getGridData () {
      this.countSelected = 0
      this.chargesGridApi.showLoadingOverlay()
      this.$http.get(`${appConfig.apiUrl}/licenses`, {
        params: {}
      })
        .then((response) => {
          this.data = response.data
          this.chargesGridApi.hideOverlay()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    licenseChargesMapping () {
      return {
        0: 'No cobrado',
        1: 'Cobrado'
      }
    },
    onSelectionChanged () {
      const selectedRows = this.chargesGridApi.getSelectedRows()
      this.countSelected = selectedRows.length
      this.selectedRows = selectedRows
    },
    openPopupChangeState(type) {
      if (type === 'charged') {
        this.popupActiveChangeStateType = type
        this.popupActiveChangeStateTitle = 'Cambiar estado cobrado'
        this.popupActiveChangeState = !this.popupActiveChangeState
      }
    },
    changeState () {
      const licensesId = []
      this.selectedRows.forEach((row) => {
        licensesId.push(row.license.id)
      })
      this.apiCall(licensesId)
    },
    apiCall (licensesId) {
      this.$http.put(`${appConfig.apiUrl}/panel/licenses/states`, {
        licenses: licensesId,
        type: this.popupActiveChangeStateType
      })
        .then(() => {
          this.$vs.notify({
            title: 'Cambio de estado',
            text: 'Realizado',
            color: 'success'
          })
          // Reload table
          this.updateData()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            color: 'danger'
          })
        })
        .then(() => {
          // always executed
          this.popupActiveChangeState = !this.popupActiveChangeState
        })
    },
    openPoModal (data) {
      this.poModalActive = true
      this.poModalData = JSON.parse(data)
    },
    getChargesContextMenuItems() {
      const selectedItems = this.chargesGridApi.getSelectedNodes()
      const thereAreSelectedItems =
        Array.isArray(selectedItems) && selectedItems.length
      return [
        'copy',
        'copyWithHeaders',
        {
          name: 'Exportar seleccionados',
          icon: '<span class="ag-icon ag-icon-save" unselectable="on"></span>',
          disabled: !thereAreSelectedItems,
          subMenu: [
            {
              name: 'CSV (.csv)',
              action: () => {
                this.chargesGridApi.exportDataAsCsv({
                  onlySelected: true,
                  columnSeparator: ';'
                })
              }
            },
            {
              name: 'Excel (.xlsx)',
              action: () => {
                this.chargesGridApi.exportDataAsExcel({ onlySelected: true })
              }
            }
          ]
        }
      ]
    },
    updateChargesSearchQuery(val) {
      this.chargesGridApi.setQuickFilter(val)
    },
    updateData() {
      this.$emit('updateData')
    },
    licensesChargesMapping () {
      return {
        0: 'No cobrado',
        1: 'Cobrado'
      }
    },
    currencyFormatter (params) {
      return this.$options.filters.currencyFormatter(params.value || 0)
    },
    putLicensesData(params, atribute) {
      let { newValue } = params
      const { data, oldValue } = params

      if (atribute === 'charged') {
        newValue = newValue === 0 ? 1 : 0
      }
      //fields required
      const body = {
        price: data.license.price,
        licensed_displays: data.license.licensed_displays,
        active_plan: data.active_plan,
        observations: data.license.observations
      }
      //delete "," in numbers
      if (newValue.includes(',')) newValue = newValue.replaceAll(',', '')
      if (String(newValue) === String(oldValue)) {
        return
      }
      this.$http
        .put(`${appConfig.apiUrl}/licenses/${data.license.id}`, {
          ...body,
          [atribute]: newValue
        })
        .then(() => {
          this.$vs.notify({
            title: 'Guardado',
            text: 'Se ha editado exitosamente',
            iconPack: 'feather',
            color: 'success'
          })
        })
        .catch((error) => {
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
          console.log(error)
        })
    },
    validDiscount(params) {
      const { discount } = params.data && params.data.license
      if (discount < 0 || discount > 100) {
        this.$vs.notify({
          title: 'Error',
          text: 'Ingrese un valor entre 0 y 100',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        return false
      }
      return true
    },
    calculateDiscount(params) {
      const { price, discount } = params.data && params.data.license
      if (discount < 0 || discount > 100) return price
      return price - price * (discount / 100)
    }
  },
  beforeMount() {
    this.frameworkComponents = {
      LicensesChargedRenderer
    }
  },
  mounted() {
    this.chargesGridApi = this.chargesGridOptions.api
    this.getGridData()
  }
}
</script>

<style>
  .editable-cell:hover::after {
  content: '\270E';
  font-family: FontAwesome;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
